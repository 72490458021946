const translations = {
    en: {
        global: {
            actionButton: "Discover benefits"
        },
        menu: {
            howWorks: "How it works",
            principles: "Principles",
            aboutUs: "About us",
            improve: "Help us improve",
            collaborate: "Collaborate",
            activityLog: "Activity log"
        },
        hero: {
            header: "Check benefits and financial support you can get",
            childAllowance: "Child allowance",
            housingBenefit: "Housing benefit",
            citizensBenefit: "Citizen's benefit",
            vocationalTrainingAllowance: "Vocational training allowance",
            manyMore: "Many more",
        }
    },
    de: {
        global: {
            actionButton: "Leistungen entdecken"
        },
        menu: {
            howWorks: "Wie es funktioniert",
            principles: "Prinzipien",
            aboutUs: "Über uns",
            improve: "Feedback",
            collaborate: "Zusammenarbeit",
            activityLog: "Aktivitätsprotokoll"
        },
        hero: {
            header: "Finde heraus, welche Leistungen und finanzielle Unterstützung du bekommen könntest",
            childAllowance: "Kinderzuschlag",
            housingBenefit: "Wohngeld",
            citizensBenefit: "Bürgergeld",
            vocationalTrainingAllowance: "Berufsausbildungsbeihilfe",
            manyMore: "Viele Weitere",
        },
    },
};

export default translations;
